import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'

import { Layout } from '../components/Layout'
import { components } from '../slices'

const MenuTemplate = ({ data }) => {
  if (!data) return null

  const pageContent = data.prismicMenu
  const page = pageContent.data || {}

  const topMenu = data.prismicTopMenu || {}
  const footer = data.prismicFooter || {}

  const seo = {
    title: page.seo_title,
    description: page.seo_description,
    keywords: page.seo_keywords,
    no_robots: page.no_robots,
  }

  const { lang, type, url } = pageContent
  const alternateLanguages = pageContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  return (
    <Layout topMenu={topMenu.data} footer={footer.data} activeDocMeta={activeDoc} seo={seo}>
      <div id="menu" className="py-80px">
        <div className="text-center mb-5">
          {/*<h6 className="">Entdecken sie</h6>*/}
          <h1 className="text-primary">Unser Menü</h1>
        </div>
      <SliceZone slices={page.body} components={components} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query menuQuery {
    prismicMenu {
      _previewable
      data {
        seo_title
        seo_description
        seo_keywords
        no_robots

        body {
          ... on PrismicSliceType {
            id
            slice_label
            slice_type
          }
          ...MenuDataBodyMenu
          ...MenuDataBodyTitle
          ...MenuDataBodyTitleAndText
        }
      }
    }
    prismicTopMenu {
      ...TopMenuFragment
    }
    prismicFooter {
      ...FooterFragment
    }
  }
`

export default withPrismicPreview(MenuTemplate)
